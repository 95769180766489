import React, {useState, useEffect, useMemo} from 'react';
import {ExternalLink, FileText, RefreshCw, Loader2, Info, History, Download, Plus, Minus} from 'lucide-react';
import {Button} from '@/components/ui/button';
import {Textarea} from '@/components/ui/textarea';
import {Card, CardContent, CardHeader, CardTitle} from '@/components/ui/card';
import {Alert, AlertDescription, AlertTitle} from "@/components/ui/alert";
import FloatingHelpButton from "@/components/FloatingHelpButton";
import Layout from "@/components/Layout";
import api from "@/utils/api";
import { useCredit } from '@/hooks/useCredit';
import {createWordCounter} from "@/utils/wordCounter";
import {
    RadioGroup,
    RadioGroupItem
} from "@/components/ui/radio-group"
import {Label} from "@/components/ui/label"

const SUBSCRIPTION_CRM_URL = 'https://afh1lmpx0eddz0cs.mikecrm.com/zsjs2X3'
const SUCCESS_CODE = 10000;
const MAX_CHARS_PER_WORD = 30;
const MAX_WORD_COUNT = 120;
const MAX_ROWS = 3;

const ReferenceRewrite = () => {
    const [rows, setRows] = useState([{
        inputText: '',
        referenceText: '',
        outputText: '',
        wordCount: 0,
        isLoading: false,
    }]);
    const [error, setError] = useState(null);
    const { refreshCredit } = useCredit();
    const [rewriteHistory, setRewriteHistory] = useState([]);
    const [isLoadingHistory, setIsLoadingHistory] = useState(true);
    const wordCounter = useMemo(() => createWordCounter(MAX_WORD_COUNT), []);
    const [thresholdType, setThresholdType] = useState(1);

    const addRow = () => {
        if (rows.length < MAX_ROWS) {
            setRows([...rows, {
                inputText: '',
                referenceText: '',
                outputText: '',
                wordCount: 0,
                isLoading: false,
            }]);
        }
    };

    const removeRow = (index) => {
        if (rows.length > 1) {
            const newRows = rows.filter((_, i) => i !== index);
            setRows(newRows);
        }
    };

    const handleTextChange = (index, field, text) => {
        const newRows = [...rows];

        if (field === 'inputText') {
            const newWordCount = wordCounter.getCurrentCount(text);
            if (wordCounter.isExceedingLimit(text)) {
                setError(`Text exceeds maximum word limit of ${MAX_WORD_COUNT} words`);
            } else {
                setError(null);
            }
            newRows[index] = {
                ...newRows[index],
                inputText: text,
                wordCount: newWordCount,
            };
        }

        setRows(newRows);
    };

    const handleRewrite = async () => {
        // Check if any input is empty
        const hasEmptyInput = rows.some(row => !row.inputText.trim());
        if (hasEmptyInput) {
            setError('Please enter text in all input fields');
            return;
        }

        // Set loading state for all rows
        const newRows = rows.map(row => ({
            ...row,
            isLoading: true
        }));
        setRows(newRows);
        setError(null);

        try {
            const response = await api.post('/rewrite/reference/', {
                user_inputs: rows.map(row => row.inputText),
                threshold_type: thresholdType
            });

            const data = response.data;
            if (data.code !== SUCCESS_CODE) {
                throw new Error(data.message);
            }

            // Update all rows with their respective outputs
            const updatedRows = rows.map((row, index) => ({
                ...row,
                outputText: data.body.contents[index].rewritten
                    ? data.body.contents[index].content
                    : 'No rewrite available - reference not found (no credits deducted)',
                referenceText: data.body.contents[index].rewritten
                    ? data.body.contents[index].reference
                    : 'No reference found (no credits deducted)',
                isLoading: false
            }));
            setRows(updatedRows);

            await fetchRewriteHistory();
            refreshCredit();
        } catch (error) {
            console.error('Error rewriting text:', error);
            setError(error.message);
            // Reset loading state on error
            setRows(rows.map(row => ({
                ...row,
                isLoading: false
            })));
        }
    };

    const fetchRewriteHistory = async () => {
        try {
            setIsLoadingHistory(true);
            const response = await api.get('/rewrite/history/reference/');
            if (response.data.code === SUCCESS_CODE) {
                setRewriteHistory(response.data.body.reference_rewrite_history || []);
            }
        } catch (error) {
            console.error('Error fetching rewrite history:', error);
        } finally {
            setIsLoadingHistory(false);
        }
    };

    useEffect(() => {
        fetchRewriteHistory();
    }, []);

    const formatDate = (timestamp) => {
        // Convert Unix timestamp (seconds) to milliseconds
        const date = new Date(timestamp * 1000);
        return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        });
    };

    const getStatusStyle = (status) => {
        switch (status) {
            case 3:
                return 'text-green-600 bg-green-50 border-green-200';
            case 1:
                return 'text-blue-600 bg-blue-50 border-blue-200';
            case 2:
                return 'text-red-600 bg-red-50 border-red-200';
            default:
                return 'text-gray-600 bg-gray-50 border-gray-200';
        }
    };

    const getDisplayStatus = (statusCode) => {
        switch (statusCode) {
            case 1:
                return 'Processing'
            case 2:
                return 'Failed'
            case 3:
                return 'Completed'
            default:
                return 'Unknown'
        }
    };

    const handleDownload = async (uniqueId) => {
        try {
            const response = await api.get(`/rewrite/download/reference/${uniqueId}`, {
                responseType: 'blob'
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            const filename = getFileNameFromContentDisposition(response) || 'rewritten.txt';
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Error downloading file:', error);
            setError('Failed to download file.');
        }
    };

    const getFileNameFromContentDisposition = (response) => {
        const contentDisposition = response.headers['content-disposition'];
        let filename = 'rewritten.txt';
        if (contentDisposition) {
            const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
            }
        }
        return filename;
    };

    const handleGetCode = () => {
        window.open(SUBSCRIPTION_CRM_URL, '_blank', 'noopener,noreferrer');
    };

    return (
        <Layout>
            <Card className="max-w-6xl mx-auto">
                <CardHeader>
                    <CardTitle className="text-center text-2xl">
                        Reference Enrich
                    </CardTitle>
                </CardHeader>
                <CardContent className="space-y-6">
                    {/* Alert component */}
                    <Alert variant="info" className="bg-pink-50">
                        <div className="px-4">
                            <AlertTitle className="text-base font-medium mb-2">How to Use</AlertTitle>
                            <AlertDescription>
                                <ol className="list-decimal ml-4 space-y-2">
                                    <li className="pl-2">This tool helps improve your writing by finding relevant academic references and rewriting your text with proper citations.</li>
                                    <li className="pl-2">You can process up to 3 paragraphs simultaneously (max 120 words each).</li>
                                    <li className="pl-2">For each paragraph, we'll find <span className="font-medium">1</span> suitable academic paper as reference and rewrite your text with in-text citations [1].
                                    </li>
                                    <li className="pl-2">Cost: <span className="font-medium">2</span> credits per successful reference found. No credits deducted if no suitable reference is found.</li>
                                    <li className="pl-2">Click <span className="font-medium">Enrich All</span> to process all paragraphs.</li>
                                    <li className="pl-2">The reference details will appear in the rightmost panel.</li>
                                    <li className="pl-2">Please note that finding suitable references may take 1-2 minutes.</li>
                                    <li className="pl-2">Having issues? Contact our customer service for assistance.</li>
                                </ol>
                            </AlertDescription>
                        </div>
                    </Alert>

                    <div className="border rounded-lg p-4 bg-gray-50">
                        <h3 className="font-medium mb-3">Reference Matching Mode</h3>
                        <RadioGroup
                            defaultValue="1"
                            value={thresholdType.toString()}
                            onValueChange={(value) => setThresholdType(parseInt(value))}
                            className="flex flex-col space-y-2"
                        >
                            <div className="flex items-center space-x-2">
                                <RadioGroupItem value="1" id="balanced" />
                                <Label htmlFor="balanced" className="font-normal">
                                    Balanced Mode - Higher success rate with relevant references
                                </Label>
                            </div>
                            <div className="flex items-center space-x-2">
                                <RadioGroupItem value="2" id="strict" />
                                <Label htmlFor="strict" className="font-normal">
                                    Strict Mode - More precise matches but lower success rate
                                </Label>
                            </div>
                        </RadioGroup>
                    </div>

                    <div className="flex flex-col space-y-8">
                        {error && (
                            <Alert variant="destructive">
                                <AlertTitle>Error</AlertTitle>
                                <AlertDescription>{error}</AlertDescription>
                            </Alert>
                        )}

                        {/* Input-Output-Reference Pairs */}
                        {rows.map((row, index) => (
                            <Card key={index} className="p-4">
                                <div className="space-y-4">
                                    <div className="flex justify-between items-center">
                                        <h3 className="text-lg font-semibold">Rewrite {index + 1}</h3>
                                        <div className="space-x-2">
                                            {index === rows.length - 1 && rows.length < MAX_ROWS && (
                                                <Button
                                                    variant="default"
                                                    size="sm"
                                                    onClick={addRow}
                                                    className="bg-gray-900 text-white hover:bg-gray-700"
                                                >
                                                    <Plus className="h-4 w-4 mr-1"/>
                                                    Add Rewrite
                                                </Button>
                                            )}
                                            {rows.length > 1 && (
                                                <Button
                                                    variant="outline"
                                                    size="sm"
                                                    onClick={() => removeRow(index)}
                                                >
                                                    <Minus className="h-4 w-4 mr-1"/>
                                                    Remove
                                                </Button>
                                            )}
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-3 gap-4">
                                        <div className="space-y-2">
                                            <span className="font-semibold">Original Text</span>
                                            <Textarea
                                                placeholder="Enter your text here..."
                                                value={row.inputText}
                                                onChange={(e) => handleTextChange(index, 'inputText', e.target.value)}
                                                className="h-48"
                                            />
                                            <div className="flex items-center space-x-2">
                                                <FileText className="h-4 w-4"/>
                                                <span className={wordCounter.isExceedingLimit(row.inputText) ? 'text-red-500' : ''}>
                                                    Word count: {wordCounter.getCountDisplay(row.inputText)}
                                                </span>
                                            </div>
                                        </div>

                                        <div className="space-y-2">
                                            <span className="font-semibold">Enriched Text With Reference</span>
                                            <div className="relative">
                                                <Textarea
                                                    value={row.outputText}
                                                    readOnly
                                                    className={`h-48 ${!row.outputText.includes('No reference available') ? '' : 'bg-gray-100 text-gray-500'}`}
                                                    placeholder="Enriched text will appear here..."
                                                />
                                                {row.isLoading && (
                                                    <div className="absolute inset-0 flex items-center justify-center bg-background/50">
                                                        <Loader2 className="h-8 w-8 animate-spin text-primary"/>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="space-y-2">
                                            <span className="font-semibold">Reference</span>
                                            <div className="relative">
                                                <div
                                                    dangerouslySetInnerHTML={{ __html: row.referenceText }}
                                                    className={`h-48 p-3 rounded-md border overflow-y-auto text-sm ${
                                                        !row.referenceText.includes('No reference found') 
                                                            ? 'bg-white' 
                                                            : 'bg-gray-100 text-gray-500'
                                                    }`}
                                                />
                                                {row.isLoading && (
                                                    <div className="absolute inset-0 flex items-center justify-center bg-background/50">
                                                        <Loader2 className="h-8 w-8 animate-spin text-primary"/>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        ))}

                        {/* Buttons */}
                        <div className="flex justify-between mt-6">
                            <Button
                                variant="outline"
                                size="lg"
                                onClick={handleGetCode}
                                className="whitespace-nowrap"
                            >
                                <ExternalLink className="mr-2 h-4 w-4"/>
                                Get Credits
                            </Button>
                            <Button
                                onClick={handleRewrite}
                                disabled={rows.some(row => row.isLoading) ||
                                        rows.some(row => !row.inputText.trim()) ||
                                        rows.some(row => wordCounter.isExceedingLimit(row.inputText))}
                                size="lg"
                                className="px-8"
                            >
                                {rows.some(row => row.isLoading) ? (
                                    <>
                                        <Loader2 className="mr-2 h-4 w-4 animate-spin"/>
                                        Rewriting...
                                    </>
                                ) : (
                                    <>
                                        <RefreshCw className="mr-2 h-4 w-4"/>
                                        Enrich All
                                    </>
                                )}
                            </Button>
                        </div>
                    </div>
                </CardContent>
            </Card>

            {/* History Card */}
            <Card className="max-w-6xl mx-auto mt-6">
                <CardHeader>
                    <CardTitle className="flex items-center space-x-2">
                        <History className="h-5 w-5"/>
                        <span>History</span>
                    </CardTitle>
                </CardHeader>
                <CardContent>
                    {isLoadingHistory ? (
                        <div className="text-center py-4">Loading history...</div>
                    ) : rewriteHistory.length === 0 ? (
                        <div className="text-center py-4 text-gray-500">
                            No rewriting history available
                        </div>
                    ) : (
                        <div className="relative w-full overflow-auto">
                            <table className="w-full caption-bottom text-sm">
                                <thead className="[&_tr]:border-b">
                                    <tr className="border-b transition-colors hover:bg-muted/50">
                                        <th className="h-10 px-4 text-left align-middle font-medium text-muted-foreground">
                                            Date
                                        </th>
                                        <th className="h-10 px-4 text-left align-middle font-medium text-muted-foreground">
                                            Status
                                        </th>
                                        <th className="h-10 px-4 text-left align-middle font-medium text-muted-foreground">
                                            ID
                                        </th>
                                        <th className="h-10 px-4 text-left align-middle font-medium text-muted-foreground">
                                            Actions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {rewriteHistory.map((item) => (
                                        <tr
                                            key={item.unique_id}
                                            className="border-b transition-colors hover:bg-muted/50"
                                        >
                                            <td className="p-4 align-middle">
                                                {formatDate(item.created_at)}
                                            </td>
                                            <td className="p-4 align-middle">
                                                <span className={`px-2 py-1 rounded-full text-xs font-medium border ${getStatusStyle(item.status)}`}>
                                                    {getDisplayStatus(item.status)}
                                                </span>
                                            </td>
                                            <td className="p-4 align-middle font-medium">
                                                {item.unique_id}
                                            </td>
                                            <td className="p-4 align-middle">
                                                <Button
                                                    variant="ghost"
                                                    size="sm"
                                                    onClick={() => handleDownload(item.unique_id)}
                                                    disabled={item.status !== 3}
                                                    className="h-8 px-2"
                                                >
                                                    <Download className="h-4 w-4 mr-1" />
                                                    Download
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </CardContent>
            </Card>

            <div className="max-w-6xl mx-auto mt-8 pt-4 border-t text-center text-sm text-gray-500">
                <p>Copyright © 2024 StraightA</p>
            </div>
        </Layout>
    );
};

export default ReferenceRewrite;
